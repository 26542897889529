.navigation-wrapper {
  position: fixed;
  bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.navigation-btn {
  background-color: #303336;
  border-color: #303336;
  
  &:hover {
    background-color: #303336;
    border-color: #303336;
    opacity: 0.9;
    color: #b3ae40;
  }

  &:focus {
    background-color: #303336;
    border-color: #303336;
  }
}