body {
  background-color: #fafafa;
  letter-spacing: -0.3px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.6;
  font-weight: 400;
  font-family: open sans, Helvetica, Arial, sans-serif;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  display: none;
}

.cesium-viewer-animationContainer {
  display: none;
}

#root .cesium-widget-credits {
  display: none;
}

#distanceLegendDiv .distance-legend {
  right: 15px;
  bottom: 0px;
}

.cesium-viewer-fullscreenContainer {
  bottom: 0px;
}

.cesium-viewer-timelineContainer {
  bottom: 0;
  left: 0px !important;
  right: 135px !important;
}

.ant-modal-mask {
  // background: transparent;
  pointer-events: none;
}
.ant-select-dropdown{
  z-index: 9999;
}

.ant-modal-wrap {
  // pointer-events: none;
}

.ant-modal {
  pointer-events: all;
}

.ant-picker-dropdown {
  z-index: 9999;
}

@media only screen and (max-width: 700px) {
  .cesium-viewer-timelineContainer {
    right: 29px !important;
  }
}

.account-dropdown {
  .ant-dropdown-menu {
    z-index: 99;
    top: 33px;
    border-radius: 0.25rem;
    padding: 0px;

    &-item {
      padding: 10px 12px;

      &-divider {
        margin: 10px 0 0 0;
      }
    }

    &-arrow-up {
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid #fff;
      position: absolute;
      top: -7px;
      right: 10px;
    }
  }
}

.sticky-200,
.sticky-80 {
  position: fixed !important;
  top: 70px;
  z-index: 999 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  height: 60px;
  box-shadow: 0 2px 4px rgb(15 34 58 / 12%);
  padding: 20px;
  right: 20px;
}

.sticky-200 {
  left: 220px;

  @media only screen and (max-width: 768px) {
    left: 100px !important;
    height: 110px;
  }
}

.sticky-80 {
  left: 100px;

  @media only screen and (max-width: 768px) {
    left: 20px !important;
    height: 110px;
  }
}

.ant-modal.modal-add-folder{
  .ant-form-item{
    margin-bottom: 8px;
  }
}

.upload-image {
  height: 150px;
}

.ant-upload-btn {
  min-height : 150px;
  padding : 0 ;
}

.input-upload-image-project, .ant-upload.ant-upload-select-picture-card {
  width: 150px;
  min-height: 150px;
  max-height: 150px;
}

.ant-notification{
  z-index: 999999999 !important;
}
.ant-image-preview-mask{
  z-index: 10000 !important;
}

.ant-image-preview-wrap {
    z-index: 108000 !important;
}
.group-button-skybox {
  display: flex;
  align-items: center;
  padding: 5px;
}